import { useStaticQuery, graphql } from 'gatsby';
import _get from 'lodash/get';
import useLocation from './useLocation';

export default function useNode() {
  const pages = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            frontmatter {
              breakpoint
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const location = useLocation();

  const currentPage = pages.allMdx.edges.find(
    edge => edge.node.fields.slug === location.pathname || `${edge.node.fields.slug}/` === location.pathname
  );
  return _get(currentPage, 'node', {});
}
