import React from 'react';
import { Box, Page } from 'fannypack';
import _get from 'lodash/get';

import Sidebar from './Sidebar';
import Navbar from './Navbar';
import useNode from '../useNode';

export default function Layout(props) {
  const { children } = props;

  const node = useNode();
  const breakpoint = _get(node, 'frontmatter.breakpoint');

  return (
    <Box>
      <Navbar />
      <Page.WithSidebar sidebarContent={<Sidebar marginTop="60px" />} sidebarWidth="300px">
        <Page.Content breakpoint={breakpoint} marginTop="60px">
          {children}
        </Page.Content>
      </Page.WithSidebar>
    </Box>
  );
}
