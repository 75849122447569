export default [
  {
    name: 'Transaction SDK',
    path: '/sdk/transaction/',
    menu: [
      { name: 'Introduction', path: '/sdk/transaction/' },
      { name: 'Virtual Terminal & Funders', path: '/sdk/transaction/supported-funders/' },
      {
        name: 'Getting started',
        path: '/sdk/transaction/getting-started/setup/',
        menu: [
          {
            name: 'Setup',
            path: '/sdk/transaction/getting-started/setup/'
          },
          {
            name: 'Submit transaction details',
            path: '/sdk/transaction/getting-started/submit/'
          },
          {
            name: 'Record transaction outcome',
            path: '/sdk/transaction/getting-started/record/'
          },
          {
            name: 'Optional settings',
            path: '/sdk/transaction/getting-started/optional-settings/'
          }
        ]
      },
      { name: 'Populating transaction attributes', path: '/sdk/transaction/prepopulating/' },
      { name: 'Callbacks', path: '/sdk/transaction/callbacks' },
      { name: 'Webhooks', path: '/sdk/transaction/webhooks' },
      { name: 'View types', path: '/sdk/transaction/view-types' },
      { name: 'Reporting, statements and receipts', path: '/sdk/transaction/reporting/' },
      { name: 'Provider support and helpdesk', path: '/sdk/transaction/support/' },
      // { name: 'Medipass Bridge', path: '/sdk/transaction/bridge/' },
      { name: 'Item codes', path: '/sdk/transaction/item-codes/' },
      { name: 'Test data', path: '/sdk/transaction/test-data/' }
    ]
  },
  // {
  //   name: 'Payment Authority SDK',
  //   path: '/sdk/payment-authority/',
  //   menu: [
  //     { name: 'Introduction', path: '/sdk/payment-authority/' },
  //     {
  //       name: 'Getting started',
  //       path: '/sdk/payment-authority/getting-started/setup/',
  //       menu: [
  //         {
  //           name: 'Setup the Payment Authority SDK',
  //           path: '/sdk/payment-authority/getting-started/setup/'
  //         },
  //         {
  //           name: 'Obtaining a token',
  //           path: '/sdk/payment-authority/getting-started/obtaining-token/'
  //         },
  //         {
  //           name: 'Authorizing a payment',
  //           path: '/sdk/payment-authority/getting-started/authorizing-payment/'
  //         }
  //       ]
  //     },
  //     { name: 'Capturing funder details', path: '/sdk/payment-authority/capturing-funder/' },
  //     { name: 'Prepopulating patient attributes', path: '/sdk/payment-authority/prepopulating/' },
  //     { name: 'Using your own submit button', path: '/sdk/payment-authority/submit-button/' }
  //   ]
  // },
  {
    name: 'SDK Reference',
    path: '/sdk/sdk-reference/',
    menu: [
      { name: 'Setup', path: '/sdk/sdk-reference/setup/' },
      { name: 'Views', path: '/sdk/sdk-reference/views/' },
      { name: 'Constants', path: '/sdk/sdk-reference/constants/' },
      { name: 'Members', path: '/sdk/sdk-reference/members/' },
      { name: 'Providers', path: '/sdk/sdk-reference/providers/' },
      { name: 'Claim items', path: '/sdk/sdk-reference/claim-items/' },
      { name: 'Transactions', path: '/sdk/sdk-reference/transactions/' }
    ]
  },
  { name: 'Playground', href: 'https://playground.medipass.io' },
  {
    name: 'Other',
    path: '/sdk/other/',
    menu: [{ name: 'Branding', path: '/sdk/other/branding/' }]
  },
  {
    name: 'Worksafe Victoria Integration',
    path: '/sdk/worksafe-vic/',
    menu: [
      {
        name: 'Guides',
        path: '/sdk/worksafe-vic/Introduction-to-Worksafe-Victoria/',
        menu: [
          {
            name: 'Introduction to Worksafe Victoria',
            path: '/sdk/worksafe-vic/Introduction-to-Worksafe-Victoria/'
          },
          {
            name: 'Claims processing',
            path: '/sdk/worksafe-vic/claims-processing-flows'
          },
          {
            name: 'Submitting claims',
            path: '/sdk/worksafe-vic/submitting-claims'
          },
          {
            name: 'Cancelling claims',
            path: '/sdk/worksafe-vic/cancelling-claims'
          },

          {
            name: 'Testing',
            path: '/sdk/worksafe-vic/testing'
          },
          {
            name: 'Playground and sample transactions',
            path: '/sdk/worksafe-vic/playground-and-sample-transactions'
          },
          {
            name: 'Security',
            path: '/sdk/worksafe-vic/security'
          },
          {
            name: 'Going live',
            path: '/sdk/worksafe-vic/going-live'
          }
        ]
      },
      {
        name: 'SDK Reference',
        path: '/sdk/worksafe-vic/Integration',
        menu: [
          {
            name: 'Integration options',
            path: '/sdk/worksafe-vic/integration-options'
          },

          {
            name: 'Tyro Health SDK',
            path: '/sdk/worksafe-vic/Integration'
          },

          {
            name: 'Optional settings',
            path: '/sdk/worksafe-vic/optional-settings'
          },
          {
            name: 'Worksafe Victoria attributes',
            path: '/sdk/worksafe-vic/worksafe-victoria-attributes'
          },
          {
            name: 'Submission',
            path: '/sdk/worksafe-vic/submission'
          },
          {
            name: 'Response',
            path: '/sdk/worksafe-vic/response'
          },
          {
            name: 'Claim flows and states',
            path: '/sdk/worksafe-vic/claim-flows-and-states'
          },
          {
            name: 'Transaction status, receipts, statements',
            path: '/sdk/worksafe-vic/transaction-status,-receipts,-statements'
          },
          {
            name: 'Callbacks',
            path: '/sdk/worksafe-vic/callbacks'
          },
          {
            name: 'Webhooks',
            path: '/sdk/worksafe-vic/webhooks'
          }
        ]
      }
    ]
  },
  {
    name: 'Comcare Integration',
    path: '/sdk/comcare/',
    menu: [
      {
        name: 'Guides',
        path: '/sdk/comcare/Introduction-to-comcare/',
        menu: [
          {
            name: 'Introduction to Comcare',
            path: '/sdk/comcare/Introduction-to-comcare/'
          },
          {
            name: 'Claims processing',
            path: '/sdk/comcare/claims-processing-flows'
          },
          {
            name: 'Submitting claims',
            path: '/sdk/comcare/submitting-claims'
          },
          {
            name: 'Cancelling claims',
            path: '/sdk/comcare/cancelling-claims'
          },
          {
            name: 'Testing',
            path: '/sdk/comcare/testing'
          },
          {
            name: 'Playground and sample transactions',
            path: '/sdk/comcare/playground-and-sample-transactions'
          },
          {
            name: 'Security',
            path: '/sdk/comcare/security'
          },
          {
            name: 'Going live',
            path: '/sdk/comcare/going-live'
          }
        ]
      },
      {
        name: 'SDK Reference',
        path: '/sdk/comcare/integration-options',
        menu: [
          {
            name: 'Integration options',
            path: '/sdk/comcare/integration-options'
          },

          {
            name: 'Tyro Health SDK setup',
            path: '/sdk/comcare/tyro-health-sdk-setup'
          },

          {
            name: 'Optional settings',
            path: '/sdk/comcare/optional-settings'
          },
          {
            name: 'Comcare attributes',
            path: '/sdk/comcare/comcare-attributes'
          },
          {
            name: 'Submission',
            path: '/sdk/comcare/submission'
          },
          {
            name: 'Response',
            path: '/sdk/comcare/response'
          },
          {
            name: 'Claim flows and states',
            path: '/sdk/comcare/claim-flows-and-states'
          },
          {
            name: 'Transaction status, receipts, statements',
            path: '/sdk/comcare/transaction-status,-receipts,-statements'
          },
          {
            name: 'Callbacks',
            path: '/sdk/comcare/callbacks'
          },
          {
            name: 'Webhooks',
            path: '/sdk/comcare/webhooks'
          }
        ]
      }
    ]
  },

  {
    name: 'EFTPOS and HealthPoint',
    path: '/sdk/tyro-eftpos-healthpoint/',
    menu: [
      {
        name: 'Intro to Tyro EFTPOS & HealthPoint',
        path: '/sdk/tyro-eftpos-healthpoint/introduction-to-tyro-eftpos-and-healthpoint/',
        menu: [
          {
            name: 'Tyro integration alternatives',
            path: '/sdk/tyro-eftpos-healthpoint/tyro-integration-alternatives'
          }
        ]
      },
      {
        name: 'Guides',
        path: '/sdk/tyro-eftpos-healthpoint/tyro-machines/',
        menu: [
          {
            name: 'Tyro machines',
            path: '/sdk/tyro-eftpos-healthpoint/tyro-machines'
          },
          {
            name: 'EFTPOS payment types',
            path: '/sdk/tyro-eftpos-healthpoint/supported-eftpos-payment-types'
          },
          {
            name: 'HeathPoint claims',
            path: '/sdk/tyro-eftpos-healthpoint/healthpoint-support'
          },
          {
            name: 'Submitting EFTPOS transactions',
            path: '/sdk/tyro-eftpos-healthpoint/eftpos-transactions'
          },
          {
            name: 'Submitting HealthPoint transactions',
            path: '/sdk/tyro-eftpos-healthpoint/healthpoint-transactions'
          },
          {
            name: 'Going live',
            path: '/sdk/tyro-eftpos-healthpoint/going-live'
          }
          // {
          //   name: 'Provider and location details',
          //   path: '/sdk/tyro-eftpos-healthpoint/provider-and-location-details'
          // },
          // {
          //   name: 'Patients',
          //   path: '/sdk/tyro-eftpos-healthpoint/patients'
          // },
          // {
          //   name: 'Items',
          //   path: '/sdk/tyro-eftpos-healthpoint/items'
          // },
          // {
          //   name: 'Receipt and statement requirements',
          //   path: '/sdk/tyro-eftpos-healthpoint/receipt-and-statement-requirements'
          // },
          // {
          //   name: 'Cancellation and Refunds',
          //   path: '/sdk/tyro-eftpos-healthpoint/cancellation-and-refunds'
          // },
          // {
          //   name: 'Tyro Integration Alternatives',
          //   path: '/sdk/tyro-eftpos-healthpoint/tyro-integration-alternatives'
          // },
          // {
          //   name: 'Claim flows and states',
          //   path: '/sdk/tyro-eftpos-healthpoint/claim-flows-and-states'
          // },
          // {
          //   name: 'Playground and Sample Transactions',
          //   path: '/sdk/tyro-eftpos-healthpoint/playground-and-sample-transactions'
          // },
          // {
          //   name: 'Going Live',
          //   path: '/sdk/tyro-eftpos-healthpoint/going-live'
          // }
        ]
      },
      {
        name: 'SDK Reference',
        path: '/sdk/tyro-eftpos-healthpoint/eftpos-attributes',
        menu: [
          {
            name: 'EFTPOS attributes',
            path: '/sdk/tyro-eftpos-healthpoint/eftpos-attributes'
          },
          {
            name: 'Healthpoint attributes',
            path: '/sdk/tyro-eftpos-healthpoint/healthpoint-attributes'
          },
          {
            name: 'Callback functions',
            path: '/sdk/tyro-eftpos-healthpoint/callbacks'
          },
          {
            name: 'Webhooks',
            path: '/sdk/tyro-eftpos-healthpoint/webhooks'
          },
          {
            name: 'Playground and sample transactions',
            path: '/sdk/tyro-eftpos-healthpoint/playground-and-sample-transactions'
          }
        ]
      },
      {
        name: 'Testing',
        path: '/sdk/tyro-eftpos-healthpoint/testing',
        menu: [
          {
            name: 'Testing',
            path: '/sdk/tyro-eftpos-healthpoint/testing'
          }
        ]
      }
    ]
  },
  {
    name: 'Medicare and DVA - direct claims API',
    path: '/sdk/medicare-dva-direct-claims-api/about-the-apis',
    menu: [
      {
        name: 'Guides',
        path: '/sdk/medicare-dva-direct-claims-api/about-the-apis',
        menu: [
          {
            name: 'About the APIs',
            path: '/sdk/medicare-dva-direct-claims-api/about-the-apis/'
          },
          {
            name: 'Authentication',
            path: '/sdk/medicare-dva-direct-claims-api/authentication'
          },
          {
            name: 'Identifying yourself',
            path: '/sdk/medicare-dva-direct-claims-api/identifying-yourself'
          },
          {
            name: 'Requests and responses',
            path: '/sdk/medicare-dva-direct-claims-api/requests-and-responses'
          },

          {
            name: 'User interface',
            path: '/sdk/medicare-dva-direct-claims-api/user-interface'
          },
          {
            name: 'Testing',
            path: '/sdk/medicare-dva-direct-claims-api/testing'
          },
          {
            name: 'Going live',
            path: '/sdk/medicare-dva-direct-claims-api/going-live'
          },
          {
            name: 'Support',
            path: '/sdk/medicare-dva-direct-claims-api/support'
          }
        ]
      },
      {
        name: 'Reference',
        path: '/sdk/medicare-dva-direct-claims-api/requests-medicare-claims',
        menu: [
          {
            name: 'Requests - Medicare claims',
            path: '/sdk/medicare-dva-direct-claims-api/requests-medicare-claims'
          },

          {
            name: 'Requests - DVA claims',
            path: '/sdk/medicare-dva-direct-claims-api/requests-dva-claims'
          },

          {
            name: 'Setting webhooks',
            path: '/sdk/medicare-dva-direct-claims-api/setting-webhooks'
          },
          {
            name: 'Response payload',
            path: '/sdk/medicare-dva-direct-claims-api/response-payload'
          },
          {
            name: 'Cancellations',
            path: '/sdk/medicare-dva-direct-claims-api/cancellations'
          },
          {
            name: 'Reports and printed statements',
            path: '/sdk/medicare-dva-direct-claims-api/reports-and-printed-statements'
          },
          {
            name: 'Reference data',
            path: '/sdk/medicare-dva-direct-claims-api/reference-data'
          }
        ]
      }
    ]
  }
];
