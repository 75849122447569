import React from 'react';
import { Box, Flex, Navigation, Link, List, css, palette, styled, theme, withPage } from 'fannypack';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const TopMenuNavigation = styled(Navigation)`
  height: 100%;

  & * {
    height: 100%;
  }

  & a {
    text-decoration: none;
  }
`;
const TopMenuButton = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  color: white;
  font-weight: ${theme('fannypack.fontWeights.semibold')};
  height: 100%;

  ${props =>
    props.isActive &&
    css`
      background-color: ${palette('primary800')};
      box-shadow: inset ${palette('primary')} 0px -4px 0px 0px;
    `}

  &:hover,
	&:focus {
    box-shadow: inset ${palette('primary')} 0px -4px 0px 0px;
  }

  &:focus {
    outline: none;
  }
`;

export default withPage(function Navbar(props) {
  const { page } = props;

  const medipassLogo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "medipass-logo-white.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Flex
      alignItems="center"
      backgroundColor="primary700"
      justifyContent={page.isCollapsed ? 'space-between' : undefined}
      height="60px"
      position="fixed"
      top="0"
      zIndex="9999999999999"
      width="100%"
    >
      <Box width={page.isCollapsed ? undefined : '300px'} paddingLeft="major-4" paddingRight="major-2">
        <Box width="150px">
          <GatsbyLink to="/">
            <GatsbyImage image={medipassLogo.placeholderImage.childImageSharp.gatsbyImageData} />
          </GatsbyLink>
        </Box>
      </Box>
      {page.isCollapsed && (
        <React.Fragment>
          {page.isSidebarOpen && (
            <TopMenuButton use="button" onClick={() => page.closeSidebar()}>
              Close
            </TopMenuButton>
          )}
          {!page.isSidebarOpen && (
            <TopMenuButton use="button" onClick={() => page.openSidebar()}>
              Menu
            </TopMenuButton>
          )}
        </React.Fragment>
      )}
      {!page.isCollapsed && (
        <TopMenuNavigation>
          <List isHorizontal>
            <List.Item>
              <TopMenuButton use="button" isActive>
                SDK Docs
              </TopMenuButton>
            </List.Item>
            <List.Item>
              <Link href="https://developers.medipass.io/docs" target="_blank" rel="noreferrer noopener">
                <TopMenuButton>API Docs</TopMenuButton>
              </Link>
            </List.Item>
          </List>
        </TopMenuNavigation>
      )}
    </Flex>
  );
});
