import { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

export default () => {
  const initialState = globalHistory.location;

  const [state, setState] = useState(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      setState(params.location);
    });
    return () => {
      removeListener();
    };
  }, [initialState]);

  return state;
};
