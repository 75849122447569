import React from 'react';
import { Box, Hidden, Icon, Link as ExternalLink, Menu as _Menu, palette, space, styled } from 'fannypack';
import { Link } from 'gatsby';

import routes from '../routes';
import useLocation from '../useLocation';

const Menu = styled(_Menu)`
  && button,
  && a {
    border-radius: 3px;
    margin-bottom: ${space(1)}rem;

    &:hover {
      background-color: unset;
      color: ${palette('primary')};
    }

    &:focus {
      background-color: unset;
      color: ${palette('primary')};
    }
  }
`;

function MenuItem(props) {
  const {
    children,
    route: { path, href },
    ...restProps
  } = props;
  if (href) {
    return (
      <Menu.Item use={ExternalLink} href={href} {...restProps}>
        {children}
      </Menu.Item>
    );
  }
  return (
    <Menu.Item use={Link} to={path} {...restProps}>
      {children}
    </Menu.Item>
  );
}

export default function Sidebar(props) {
  const location = useLocation();

  function getCurrentRoute(routes) {
    let currentRoute;
    routes.forEach(route => {
      if (!currentRoute && route.menu) {
        currentRoute = getCurrentRoute(route.menu);
      }
      if (route.path === location.pathname) {
        currentRoute = route;
      }
    });
    return currentRoute;
  }
  const currentRoute = getCurrentRoute(routes) || { path: '' };

  return (
    <Box {...props}>
      <Menu padding="major-1">
        {routes.map(route =>
          route.menu ? (
            <Hidden.Container key={route.name} defaultVisible={currentRoute.path.includes(route.path)}>
              {hidden => (
                <React.Fragment>
                  <Menu.Item display="flex" alignItems="center" justifyContent="space-between" onClick={hidden.toggle}>
                    {route.name}
                    <Icon icon={hidden.isVisible ? 'solid-chevron-up' : 'solid-chevron-down'} top="0" />
                  </Menu.Item>
                  {hidden.isVisible && (
                    <Box paddingLeft="major-2" width="100%">
                      {route.menu.map((route, i) =>
                        route.menu ? (
                          <React.Fragment>
                            <MenuItem
                              route={route}
                              color={currentRoute.path.includes(route.path) ? 'primary' : undefined}
                              fontWeight="normal"
                            >
                              {route.name}
                            </MenuItem>
                            {route.menu.map(route => (
                              <MenuItem
                                key={route.name}
                                marginLeft="major-2"
                                fontSize="150"
                                route={route}
                                color={currentRoute.path === route.path ? 'primary' : undefined}
                                fontWeight="normal"
                              >
                                {route.name}
                              </MenuItem>
                            ))}
                          </React.Fragment>
                        ) : (
                          <MenuItem
                            route={route}
                            color={currentRoute.path === route.path ? 'primary' : undefined}
                            fontWeight="normal"
                          >
                            {route.name}
                          </MenuItem>
                        )
                      )}
                    </Box>
                  )}
                </React.Fragment>
              )}
            </Hidden.Container>
          ) : (
            <MenuItem route={route} color={currentRoute.path === route.path ? 'primary' : undefined}>
              {route.name}
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  );
}
